import PromineoViewGrid, {
  PromineoGridToolbarConfig,
  RowOperationConfig,
} from "components/common/grid/PromineoViewGrid";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import { Column, Scrolling } from "devextreme-react/data-grid";
import DetailedTemplateResponse from "interfaces/response/DetailedTemplateResponse";
import TemplateResponseBase from "interfaces/response/TemplateResponseBase";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { TEMPLATES } from "shared/constants/RoutePathConstants";
import {
  loadOwnTemplatesAsync,
  copyTemplateByIdAsync,
} from "store/actions/TemplateActions";
import { useAppDispatch, useAppSelector } from "store/hooks";
import ShareTemplateWithTenantDialog from "../share-template/ShareTemplateWithTenantDialog";
import "components/common/grid/styles/PromineoUIGrid.css";
import { getLocalFormattedDateTime } from "shared/utilities/DateTimeUtility";
import LabelHolder from "components/common/LabelHolder";
import { SupportedLabelEntity } from "shared/enums/feature/SupportedLabelEntity";
import PromineoConfirmationDialog from "components/common/controls/PromineoConfirmationDialog";
import { unwrapResult } from "@reduxjs/toolkit";
import ResponseBase from "interfaces/response/ResponseBase";

interface Props {
  height: number;
}

export default function OwnTemplate(props: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [selectedTemplateToShare, setSelectedTemplateToShare] = useState<{
    templateId: number;
    templateTitle: string;
  } | null>(null);

  const [selectedTemplateToCopy, setSelectedTemplateToCopy] = useState<{
    templateId: number;
    templateTitle: string;
  } | null>(null);

  useEffect(() => {
    displayLoadingPanel();
    dispatch(loadOwnTemplatesAsync()).finally(hideLoadingPanel);
  }, []);

  const ownTemplates = useAppSelector(
    (store) => store.templateData.ownTemplates
  );

  const handleAddNewTemplateClicked = useCallback(() => {
    navigate(`${TEMPLATES}/new`);
  }, []);

  const handleOpenTemplateClicked = useCallback(
    (evt: { data: TemplateResponseBase }) => {
      navigate(`${TEMPLATES}/${evt.data.id}`);
    },
    []
  );

  const handleShareTemplateClicked = useCallback(
    (evt: { data: TemplateResponseBase }) => {
      setSelectedTemplateToShare({
        templateId: evt.data.id,
        templateTitle: evt.data.title,
      });
    },
    []
  );

  const handleCopyTemplateClicked = useCallback(
    (evt: { data: DetailedTemplateResponse }) => {
      setSelectedTemplateToCopy({
        templateId: evt.data.id,
        templateTitle: evt.data.title,
      });
    },
    []
  );

  const handleRowClick = useCallback(
    (evt: { data: DetailedTemplateResponse }) => {
      navigate(`${TEMPLATES}/${evt.data.id}`);
    },
    []
  );

  const handleCopyConfirmClicked = useCallback(() => {
    if (selectedTemplateToCopy) {
      displayLoadingPanel();
      dispatch(copyTemplateByIdAsync(selectedTemplateToCopy.templateId))
        .then(unwrapResult)
        .then((copiedTemplate: ResponseBase) => {
          navigate(`${TEMPLATES}/${copiedTemplate.id}/edit`);
        })
        .finally(hideLoadingPanel);
      setSelectedTemplateToCopy(null);
    }
  }, [selectedTemplateToCopy]);

  const handleCancelCopyConfirmClicked = useCallback(() => {
    setSelectedTemplateToCopy(null);
  }, []);

  const toolbarConfig = useRef<PromineoGridToolbarConfig>({
    dislplaySearchPanel: true,
    addNewButtonOptions: {
      isVisible: true,
      text: "New Template",
      onClick: handleAddNewTemplateClicked,
    },
  });

  const rowOperationConfig = useRef<RowOperationConfig>({
    visible: true,
    displayDefault: false,
    items: [
      {
        text: "Open",
        onClick: handleOpenTemplateClicked,
        visible: true,
      },
      {
        text: "Share",
        onClick: handleShareTemplateClicked,
        visible: true,
      },
      {
        text: "Copy",
        onClick: handleCopyTemplateClicked,
        visible: true,
      },
    ],
  });

  const LabelCellTemplate = useCallback((e: any) => {
    return (
      <LabelHolder
        labels={e.data.data.labels}
        labelType={SupportedLabelEntity.Template}
      />
    );
  }, []);

  const LastModifiedCellComponent = useCallback((e: any) => {
    return <>{getLocalFormattedDateTime(e.data.data.lastModified)}</>;
  }, []);

  return (
    <div>
      <PromineoViewGrid
        dataSource={ownTemplates}
        onRowClick={handleRowClick}
        className="promineo-ui-grid"
        toolbarConfig={toolbarConfig.current}
        rowOperationConfig={rowOperationConfig.current}
        height={props.height}
      >
        <Scrolling mode={"virtual"} rowRenderingMode={"virtual"} />
        <Column caption={"Title"} dataField={"title"} />
        <Column caption={"ID"} dataField={"id"} alignment="left" />
        <Column
          caption={"Share with"}
          dataField={"shareCount"}
          alignment="left"
        />
        <Column
          caption={"Labels"}
          dataField={"labels"}
          cssClass={"custom-control-padding"}
          cellComponent={LabelCellTemplate}
          allowFiltering={false}
        />
        <Column
          caption={"# of own fields"}
          dataField={"fieldCount"}
          alignment="left"
        />
        <Column
          caption={"# of own config"}
          dataField={"ownConfigsCount"}
          alignment="left"
        />
        <Column
          caption={"Last updated"}
          dataField={"lastModified"}
          alignment={"left"}
          cellComponent={LastModifiedCellComponent}
        />
        <Column
          caption={"Last updated by"}
          dataField={"lastModifierName"}
          alignment="left"
        />
      </PromineoViewGrid>

      {selectedTemplateToShare && (
        <ShareTemplateWithTenantDialog
          onCancel={() => {
            setSelectedTemplateToShare(null);
          }}
          templateToShare={selectedTemplateToShare}
        />
      )}

      {selectedTemplateToCopy && (
        <PromineoConfirmationDialog
          onConfirm={handleCopyConfirmClicked}
          onCancel={handleCancelCopyConfirmClicked}
          content={`Are you sure you want to copy template "${selectedTemplateToCopy.templateTitle}"?`}
          cancelButtonText="Cancel"
          confirmButtonText="Confirm"
        />
      )}
    </div>
  );
}
