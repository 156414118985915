import PromineoViewGrid, {
  RowOperationConfig,
} from "components/common/grid/PromineoViewGrid";
import "components/common/grid/styles/PromineoUIGrid.css";
import { Column, Scrolling } from "devextreme-react/data-grid";
import ArchiveExchangeAgreementListingResponse from "interfaces/response/ArchiveExchangeAgreementListingResponse";
import { useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ARCHIVE_IEAS } from "shared/constants/RoutePathConstants";

interface Props {
  ieas: ArchiveExchangeAgreementListingResponse[];
  height: number;
}

export default function ArchiveIEAGrid(props: Props) {
  const { ieas, height } = props;
  let navigate = useNavigate();

  const handleInactiveIEAClick = (
    iea: ArchiveExchangeAgreementListingResponse
  ) => {
    navigate(`${ARCHIVE_IEAS}/${iea.id}`);
  };

  const handleRowClick = useCallback((data: any) => {
    handleInactiveIEAClick(data.data);
  }, []);

  const toolbarConfig = useRef({
    dislplaySearchPanel: true,
  });

  const rowOperationConfig = useRef<RowOperationConfig>({
    visible: true,
    items: [
      {
        onClick: (data: any) => {
          const iea: ArchiveExchangeAgreementListingResponse = data.data;
          handleInactiveIEAClick(iea);
        },
        text: "View",
        visible: true,
      },
    ],
  });

  return (
    <>
      <PromineoViewGrid
        dataSource={ieas}
        height={height}
        className={`archive-ieas-grid promineo-ui-grid`}
        onRowClick={handleRowClick}
        toolbarConfig={toolbarConfig.current}
        rowOperationConfig={rowOperationConfig.current}
      >
        <Scrolling mode={"virtual"} rowRenderingMode={"virtual"} />
        <Column
          caption={"IEA Id"}
          dataField="id"
          alignment="left"
          width={100}
        />
        <Column caption={"IEA Title"} dataField="title" alignment="left" />
        <Column
          caption={"Template"}
          dataField="templateName"
          alignment="left"
        />
        <Column
          caption={"Sending connector (host system)"}
          dataField="sendingConnectorText"
          alignment="left"
        />
        <Column
          caption={"Receiving connector (host system)"}
          dataField="receivingConnectorText"
          alignment="left"
        />
        <Column caption={"Sender"} dataField="sender" alignment="left" />
        <Column caption={"Receiver"} dataField="receiver" alignment="left" />
        <Column caption={"Role"} dataField="roleText" alignment="left" />
        <Column
          caption={"Expiration date"}
          dataField="expirationDate"
          alignment="left"
          sortIndex={0}
          sortOrder={"asc"}
        />
      </PromineoViewGrid>
    </>
  );
}
