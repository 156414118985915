import { StepThreeNewIeaProps } from "features/ieas/active-ieas/new-iea/StepThreeNewIEA";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { ExchangeRoleEnum } from "shared/enums/feature/ExchangeRoleEnum";
import { FrequencyType } from "shared/enums/feature/FrequencyType";

interface Props {
  ownerExchangeRole: ExchangeRoleEnum;
}

interface HookResponseType {
  stepThreeProps: StepThreeNewIeaProps;
  scheduleRequest: ScheduleRequest;
  isScheduleRequestValid: boolean;
}

interface ScheduleRequest {
  startDate: string;
  expirationDate: string;
  labelIdentifiers: number[];
  comments: string;
  frequencyType: FrequencyType;
  lagInMinutes: number;
  predecessorAgreementId?: number;
  executionCronExpression: string;
}

const defaultScheduleRequest: ScheduleRequest = {
  startDate: "",
  expirationDate: "",
  labelIdentifiers: [],
  comments: "",
  frequencyType: -1 as FrequencyType.CronExpression,
  lagInMinutes: 0,
  executionCronExpression: "",
};

export default function useStepThreeNewIeaDataHook(
  props: Props
): HookResponseType {
  const [isValidRequest, setIsValidRequest] = useState(false);

  const [scheduleRequest, setScheduleRequest] = useState<ScheduleRequest>(
    defaultScheduleRequest
  );

  useEffect(() => {
    let isValid = true;
    if (
      !scheduleRequest.startDate ||
      !scheduleRequest.expirationDate ||
      moment(scheduleRequest.startDate).isSameOrAfter(
        moment(scheduleRequest.expirationDate)
      ) ||
      scheduleRequest.frequencyType < 0 ||
      (scheduleRequest.frequencyType === FrequencyType.Predecessor &&
        !scheduleRequest.predecessorAgreementId) ||
      (scheduleRequest.frequencyType === FrequencyType.CronExpression &&
        !!!scheduleRequest.executionCronExpression)
    ) {
      isValid = false;
    }
    setIsValidRequest(isValid);
  }, [scheduleRequest]);

  const handleFrequencyTypeChange = (frequencyType: FrequencyType) => {
    setScheduleRequest((prev) => {
      return { ...prev, frequencyType, predecessorAgreementId: undefined, lagInMinutes: 0 };
    });
  };

  const handleLagMinutesChange = (lagInMinutes: number) => {
    setScheduleRequest((prev) => {
      return { ...prev, lagInMinutes: lagInMinutes };
    });
  };

  const handlePredecessorAgreementIdChange = (value: number) => {
    setScheduleRequest((prev) => {
      return {
        ...prev,
        predecessorAgreementId: value,
      };
    });
  };

  const handleStartDateChange = (value: string) => {
    setScheduleRequest((prev) => {
      return {
        ...prev,
        startDate: value,
        expirationDate: "",
      };
    });
  };

  const handleExpirationDateChange = (value: string) => {
    setScheduleRequest((prev) => {
      return {
        ...prev,
        expirationDate: value,
      };
    });
  };

  const handleLabelIdentifierChange = (values: number[]) => {
    setScheduleRequest((prev) => {
      return {
        ...prev,
        labelIdentifiers: values,
      };
    });
  };

  const handleCommentChange = (value: string) => {
    setScheduleRequest((prev) => {
      return {
        ...prev,
        comments: value,
      };
    });
  };

  const handleCronExpressionChange = (value: string) => {
    setScheduleRequest((prev) => {
      return {
        ...prev,
        executionCronExpression: value,
      };
    });
  };

  const response = useMemo<HookResponseType>(() => {
    return {
      stepThreeProps: {
        scheduleProps: {
          ownerExchangeRole: props.ownerExchangeRole,
          onValueChange: () => {},
          onFrequencyTypeChange: handleFrequencyTypeChange,
          onLagMinutesChange: handleLagMinutesChange,
          onPredecessorAgreementIdChange: handlePredecessorAgreementIdChange,
          onCommentChange: handleCommentChange,
          onExpirationDateChange: handleExpirationDateChange,
          onStartDateChange: handleStartDateChange,
          onLabelIndentifiersChange: handleLabelIdentifierChange,
          onCronExpressionChange: handleCronExpressionChange,
          startDate: scheduleRequest.startDate,
          expirationDate: scheduleRequest.expirationDate,
        },
      },
      isScheduleRequestValid: isValidRequest,
      scheduleRequest: scheduleRequest,
    };
  }, [scheduleRequest, props, isValidRequest]);

  return response;
}
