import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { autonomousComponentSlice } from "./slices/AutonomousComponentSlice";
import { bulkInsertUISlice } from "./slices/BulkInsertUISlice";
import { configSlice } from "./slices/ConfigSlice";
import { conflictSlice } from "./slices/ConflictSlice";
import { connectorSlice } from "./slices/ConnectorSlice";
import { coreFieldSlice } from "./slices/CoreFieldSlice";
import { dataTransferSlice } from "./slices/DataTransferSlice";
import { dropdownValuesSlice } from "./slices/DropdownValueSlice";
import { errorSlice } from "./slices/ErrorSlice";
import { exchangeAgreementSlice } from "./slices/ExchangeAgreementSlice";
import { fieldSlice } from "./slices/FieldSlice";
import { hostSystemSlice } from "./slices/HostSystemSlice";
import { ilapAnalyticsSlice } from "./slices/IlapAnalyticsSlice";
import { labelSlice } from "./slices/LabelSlice";
import { loadingPanelSlice } from "./slices/LoadingPanelSlice";
import { mappingFormulaSlice } from "./slices/MappingFormulaSlice";
import { systemAdminSlice } from "./slices/SystemAdminSlice";
import { templateSlice } from "./slices/TemplateSlice";
import { tenantAdminSlice } from "./slices/TenantAdminSlice";
import { tenantSlice } from "./slices/TenantSlice";
import { userSlice } from "./slices/UserSlice";
import { errorMiddleware } from "shared/connector/ApiConnectorInterceptor";

export const store = configureStore({
  reducer: {
    userData: userSlice.reducer,
    bulkInsertUIData: bulkInsertUISlice.reducer,
    templateData: templateSlice.reducer,
    fieldData: fieldSlice.reducer,
    labelData: labelSlice.reducer,
    connectorData: connectorSlice.reducer,
    loadingPanelData: loadingPanelSlice.reducer,
    dropdownData: dropdownValuesSlice.reducer,
    configData: configSlice.reducer,
    exchangeAgreementData: exchangeAgreementSlice.reducer,
    dataTransferData: dataTransferSlice.reducer,
    coreFieldData: coreFieldSlice.reducer,
    tenantData: tenantSlice.reducer,
    tenantAdminData: tenantAdminSlice.reducer,
    systemAdminData: systemAdminSlice.reducer,
    hostSystemData: hostSystemSlice.reducer,
    autonomousComponentData: autonomousComponentSlice.reducer,
    ilapAnalyticsData: ilapAnalyticsSlice.reducer,
    conflictData: conflictSlice.reducer,
    mappingFormulaData: mappingFormulaSlice.reducer,
    errorData: errorSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(errorMiddleware)
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export type Store = typeof store;
