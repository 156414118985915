export enum Frequency {
  "Daily" = "day",
  "Weekly" = "week",
  "Monthly" = "month",
  "PredecessorIEA" = "predecessor",
  "Custom" = "custom",
}

export enum WeekDay {
  "Sunday" = 0,
  "Monday" = 1,
  "Tuesday" = 2,
  "Wednesday" = 3,
  "Thursday" = 4,
  "Friday" = 5,
  "Saturday" = 6
}

export enum MonthlyDay {
  "LastDay" = 0,
  "LastSunday" = 1,
}

export interface Time {
  hour: number;
  minute: number;
}

export interface TimeResponse {
  time: Time;
  displayValue: string;
}

export interface FrequencyResponse {
  frequency: Frequency;
  displayValue: string;
  visible: boolean;
}

export interface WeekDayResponse {
  weekDay: WeekDay;
  displayValue: string;
}

export interface MonthlyDayResponse {
  monthlyDay: MonthlyDay;
  displayValue: string;
}

export interface LagDayResponse {
  lagDay: number;
  displayValue: string;
  disabled?: boolean;
}

export function getFrequencyDropdownData() {
  const frequencyDropdownSource: FrequencyResponse[] = [
    {
      frequency: Frequency.Daily,
      displayValue: "Daily",
      visible: true
    },
    {
      frequency: Frequency.Weekly,
      displayValue: "Weekly",
      visible: true
    },
    {
      frequency: Frequency.Monthly,
      displayValue: "Monthly",
      visible: true
    },
    {
      frequency: Frequency.PredecessorIEA,
      displayValue: "PredecessorIEA",
      visible: true
    },
    {
      frequency: Frequency.Custom,
      displayValue: "Custom",
      visible: true
    }
  ];

  return frequencyDropdownSource;
}

export function getWeeklyDaysDropdownData() {
  const weeklyDaysDropdownSource: WeekDayResponse[] = [
    {
      weekDay: WeekDay.Sunday,
      displayValue: "Sunday"
    },
    {
      weekDay: WeekDay.Monday,
      displayValue: "Monday"
    },
    {
      weekDay: WeekDay.Tuesday,
      displayValue: "Tuesday"
    },
    {
      weekDay: WeekDay.Wednesday,
      displayValue: "Wednesday"
    },
    {
      weekDay: WeekDay.Thursday,
      displayValue: "Thursday"
    },
    {
      weekDay: WeekDay.Friday,
      displayValue: "Friday"
    },
    {
      weekDay: WeekDay.Saturday,
      displayValue: "Saturday"
    }
  ];

  return weeklyDaysDropdownSource;
}

export function getMonthlyDayDropdownData() {
  const monthlyDayDropdownSource: MonthlyDayResponse[] = [
    {
      monthlyDay: MonthlyDay.LastDay,
      displayValue: "Last day"
    },
    {
      monthlyDay: MonthlyDay.LastSunday,
      displayValue: "Last sunday"
    }
  ];

  return monthlyDayDropdownSource;
}

export function getLagDayDropdownData() {
  const lagDayDropdownSource: LagDayResponse[] = [
    {
      lagDay: -1,
      displayValue: "Select lag",
      disabled: true
    },
    {
      lagDay: 0,
      displayValue: "0 day"
    },
    {
      lagDay: 1,
      displayValue: "1 day"
    },
    {
      lagDay: 2,
      displayValue: "2 days"
    },
    {
      lagDay: 3,
      displayValue: "3 days"
    },
    {
      lagDay: 4,
      displayValue: "4 days"
    },
    {
      lagDay: 5,
      displayValue: "5 days"
    },
    {
      lagDay: 6,
      displayValue: "6 days"
    }
  ];

  return lagDayDropdownSource;
}

export function getTimeDropdownData() {
  let timeDropdownSource: TimeResponse[] = [];
  let timeList: TimeResponse;
  let hourString;
  let minuteString;

  for (let hour = 0; hour < 24; hour++) {
    hourString = hour.toString();
    if (hourString.length === 1) {
      hourString = '0' + hourString;
    }

    for (let minute = 0; minute < 60; minute += 30) {
      minuteString = minute.toString();
      if (minuteString.length === 1) {
        minuteString = '0' + minuteString;
      }

      timeList = {
        time : {
          hour: hour,
          minute: minute
        },
        displayValue: hourString + ':' + minuteString
      }

      timeDropdownSource.push(timeList);
    }
  }

  return timeDropdownSource;
}