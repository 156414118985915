import PromineoViewGrid, {
  PromineoGridToolbarConfig,
  RowOperationConfig,
} from "components/common/grid/PromineoViewGrid";
import "components/common/grid/styles/PromineoUIGrid.css";
import LabelHolder from "components/common/LabelHolder";
import { Column, Scrolling } from "devextreme-react/data-grid";
import ConfigResponse from "interfaces/response/ConfigResponse";
import { ReactElement, useCallback, useRef } from "react";
import { useNavigate } from "react-router";
import { CONFIGS } from "shared/constants/RoutePathConstants";
import { SupportedLabelEntity } from "shared/enums/feature/SupportedLabelEntity";
import { booleanSortComparator } from "shared/utilities/CommonUtility";
import TitleCellComponent from "./grid-cell-component/TitleCellComponent";

interface Props {
  configs: ConfigResponse[];
  height: number;
  onCopyConfigClick: (data: any) => void;
  filterComponent: ReactElement;
}

export default function ConfigsGrid(props: Props) {
  const navigate = useNavigate();

  const navigateToDetailConfig = useCallback((config: ConfigResponse) => {
    navigate(`${CONFIGS}/${config.id}`);
  }, []);

  const handleRowClick = useCallback(
    (evt: { data: ConfigResponse }) => {
      navigateToDetailConfig(evt.data);
    },
    [navigateToDetailConfig]
  );

  const handleOpenConfigClick = useCallback(
    (evt: { data: ConfigResponse }) => {
      navigateToDetailConfig(evt.data);
    },
    [navigateToDetailConfig]
  );

  const toolbarConfig = useRef<PromineoGridToolbarConfig>({
    dislplaySearchPanel: true,
    addNewButtonOptions: {
      isVisible: true,
      text: "New Config",
      onClick: () => navigate(`${CONFIGS}/new`),
    },
  });

  const rowOperationConfig = useRef<RowOperationConfig>({
    visible: true,
    displayDefault: false,
    items: [
      {
        text: "Open",
        onClick: handleOpenConfigClick,
        visible: true,
      },
      {
        text: "Copy",
        onClick: props.onCopyConfigClick,
        visible: true,
      },
    ],
  });

  const LabelCellTemplate = useCallback((e: any) => {
    return (
      <LabelHolder
        labels={e.data.labels}
        labelType={SupportedLabelEntity.Template}
      />
    );
  }, []);

  return (
    <PromineoViewGrid
      height={props.height}
      dataSource={props.configs}
      onRowClick={handleRowClick}
      className="promineo-ui-grid"
      toolbarConfig={toolbarConfig.current}
      rowOperationConfig={rowOperationConfig.current}
      additionalWidget={props.filterComponent}
    >
      <Scrolling mode={"virtual"} rowRenderingMode={"virtual"} />
      <Column caption={"ID"} dataField={"id"} alignment="left" width={70} />
      <Column
        caption={"Title"}
        cssClass={"custom-control-padding"}
        dataField={"title"}
        cellComponent={TitleCellComponent}
        calculateSortValue={"updateRequired"}
        sortingMethod={booleanSortComparator}
        sortOrder={"desc"}
        minWidth={200}
      />
      <Column caption={"Status"} dataField={"statusText"} width={150} />
      <Column caption={"Direction"} dataField={"directionText"} width={80} />
      <Column caption={"Connector"} dataField={"connectorName"} />
      <Column caption={"Template"} dataField={"templateTitle"} />
      <Column
        caption={"# of Schedules"}
        dataField={"scheduleCount"}
        alignment="left"
        width={125}
      />
      <Column
        caption={"Labels"}
        dataField={"labels"}
        cssClass={"custom-control-padding"}
        cellRender={LabelCellTemplate}
        allowFiltering={false}
      />
      <Column caption={"Created By"} dataField={"createdBy"} width={200} />
    </PromineoViewGrid>
  );
}
