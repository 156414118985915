import Filter from "components/common/Filter";
import PromineoViewGrid, {
  PromineoGridToolbarConfig,
  RowOperationConfig,
} from "components/common/grid/PromineoViewGrid";
import { Column, Scrolling } from "devextreme-react/data-grid";
import SystemUserResponse from "interfaces/response/SystemUserResponse";
import { useCallback, useRef } from "react";
import { copyToClipboard } from "shared/utilities/CommonUtility";
import { getLocalFormattedDateTime } from "shared/utilities/DateTimeUtility";
import { toastSuccess } from "shared/utilities/ToastUtility";
import StatusDisplayComponent from "./StatusDisplayComponent";
import CopyIcon from "components/icons/CopyIcon";

interface Props {
  systemUsers: SystemUserResponse[];
  height: number;
}
export default function SystemAdminUserGrid(props: Props) {
  const handleCopyEmailClick = async (data: { data: SystemUserResponse }) => {
    await copyToClipboard(data.data.email);
    toastSuccess("Email address copied to clipboard.");
  };

  const handleCopyAllEmailClick = async () => {
    var concatenatedEmails = props.systemUsers
      .map((user) => user.email)
      .filter((ce) => ce)
      .join(";");
    await copyToClipboard(concatenatedEmails);
    toastSuccess("All email addresses are copied to clipboard.");
  };

  const rowOperationConfig = useRef<RowOperationConfig>({
    visible: true,
    displayDefault: false,
    items: [
      {
        text: "Copy email address",
        visible: true,
        onClick: handleCopyEmailClick,
      },
    ],
  });

  const toolbarConfig = useRef<PromineoGridToolbarConfig>({
    dislplaySearchPanel: true,
    addNewButtonOptions: {
      isVisible: true,
      text: "Copy email address",
      icon: <CopyIcon />,
      onClick: handleCopyAllEmailClick,
    },
  });

  const LastLoginDisplay = useCallback((data: SystemUserResponse) => {
    return data.lastLogin
      ? getLocalFormattedDateTime(data.lastLogin.toString())
      : "-";
  }, []);

  return (
    <>
      <PromineoViewGrid
        dataSource={props.systemUsers}
        className="promineo-ui-grid"
        toolbarConfig={toolbarConfig.current}
        rowOperationConfig={rowOperationConfig.current}
        additionalWidget={<Filter />}
        height={props.height}
      >
        <Scrolling mode={"virtual"} rowRenderingMode={"virtual"} />
        <Column
          caption={"User ID"}
          dataField="id"
          alignment="left"
          width={100}
        />
        <Column caption={"Username"} dataField="fullName" />
        <Column
          caption={"Status"}
          dataField="statusDisplay"
          width={180}
          cssClass={"custom-control-padding"}
          cellComponent={StatusDisplayComponent}
        />
        <Column caption={"Tenant"} dataField={"tenantName"} />
        <Column caption={"E-mail address"} dataField={"email"} />
        <Column
          caption={"Application role"}
          dataField="applicationRoleDisplay"
          alignment={"left"}
        />
        <Column
          caption={"Last login"}
          dataField="lastLogin"
          width={180}
          calculateCellValue={LastLoginDisplay}
        />
        <Column
          caption={"# of IEAs"}
          dataField="ieaCount"
          alignment="left"
          width={100}
        />
      </PromineoViewGrid>
    </>
  );
}
