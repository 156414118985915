import StepProgressBar from "components/common/step-progressbar/StepProgressBar";
import PromineoModal from "components/modal/PromineoModal";
import { useEffect, useMemo, useState } from "react";
import StepOneNewIEA from "./StepOneNewIEA";
import PromineoButton, {
  PromineoButtonType,
} from "components/common/controls/buttons/PromineoButton";
import StepTwoNewIEA from "./StepTwoNewIEA";
import StepThreeNewIEA from "./StepThreeNewIEA";
import StepFourNewIEA from "./StepFourNewIEA";
import StepFiveNewIEA from "./StepFiveNewIEA";
import PromineoTabPanel from "components/common/controls/PromineoTabPanel";
import { Item } from "devextreme-react/tab-panel";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import {
  loadConfigForExchangeAgreementAsync,
  loadOwnerConfigForExchangeAgreementAsync,
} from "store/actions/ConfigActions";
import useStepOneNewIeaDataHook from "hooks/new-iea/StepOneNewIeaDataHook";
import useStepTwoNewIeaDataHook from "hooks/new-iea/StepTwoNewIeaDataHook";
import { resetConfigForExchangeAgreement } from "store/slices/ConfigSlice";
import { ConfigDirection } from "shared/enums/feature/ConfigDirection";
import { ExchangeRoleEnum } from "shared/enums/feature/ExchangeRoleEnum";
import useStepThreeNewIeaDataHook from "hooks/new-iea/StepThreeNewIeaDataHook";
import useStepFourNewIeaDataHook, {
  StepFourDataHookProps,
} from "hooks/new-iea/StepFourNewIeaDataHook";
import useStepFiveNewIeaDataHook, {
  StepFiveDataHookProps,
} from "hooks/new-iea/StepFiveNewIeaDataHook";
import OwnerExchangeAgreementWriteRequest from "interfaces/request/OwnerExchangeAgreementWriteRequest";
import { createNewExchangeAgreementForOwnerAsync } from "store/actions/ExchangeAgreementActions";
import { toastSuccess } from "shared/utilities/ToastUtility";
import { useNavigate } from "react-router-dom";
import { NEW_IEAS } from "shared/constants/RoutePathConstants";
import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import useStepValidation from "hooks/new-iea/IEACreationStepValidationHook";
import PromineoConfirmationDialog from "components/common/controls/PromineoConfirmationDialog";

interface Props {
  onClose: () => void;
}

export default function NewIEAWizard(props: Props) {
  const totalStepForSender = 5;
  const totalStepForReceiver = 4;
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [totalStep, setTotalStep] = useState(totalStepForReceiver);
  const [
    isCancelConfirmationDialogVisible,
    setIsCancelConfirmationDialogVisible,
  ] = useState(false);

  const loggedInUser = useAppSelector((state) => state.userData.mySelfResponse);

  const goToNextStep = () => {
    setCurrentStep((prev) => (prev < totalStep ? prev + 1 : prev));
  };

  const goToPrevStep = () => {
    setCurrentStep((prev) => (prev > 1 ? prev - 1 : prev));
  };

  const handleCancelOrBackClick = () => {
    if (currentStep === 1) {
      setIsCancelConfirmationDialogVisible(true);
    } else {
      goToPrevStep();
    }
  };

  const handleNextOrSubmitClick = () => {
    if (currentStep === totalStep) {
      handleSubmitIEA();
    } else {
      goToNextStep();
    }
  };

  const dispatch = useAppDispatch();

  const ownerConfigs = useAppSelector(
    (state) => state.configData.ownerConfigForExchangeAgreement
  );

  const ownerConfigSetting = useAppSelector(
    (state) => state.configData.configForExchangeAgreement
  );

  // Step 1 hook
  const stepOneHookProps = useMemo(() => {
    return {
      configsForExchangeAgreement: ownerConfigs,
    };
  }, [ownerConfigs]);

  const { coreInfoRequest, stepOneProps, isCoreInfoRequestValid } =
    useStepOneNewIeaDataHook(stepOneHookProps);

  // Step 2 hook
  const stepTwoHookProps = useMemo(() => {
    return {
      direction: coreInfoRequest.exchangeRole,
      loggedInUser: loggedInUser!,
      ownerConfig: ownerConfigSetting,
      ownerConfiguration: ownerConfigs,
      templateId: coreInfoRequest.templateId,
    };
  }, [
    coreInfoRequest,
    coreInfoRequest,
    loggedInUser,
    ownerConfigs,
    ownerConfigSetting,
  ]);

  const { ownerConfigRequest, stepTwoProps, isOwnerConfigRequestValid } =
    useStepTwoNewIeaDataHook(stepTwoHookProps);

  // Step 3 hook
  const stepThreeHookProps = useMemo(() => {
    return {
      ownerExchangeRole:
        coreInfoRequest.exchangeRole === ConfigDirection.Receiving
          ? ExchangeRoleEnum.Receiver
          : ExchangeRoleEnum.Sender,
    };
  }, [coreInfoRequest]);

  const { stepThreeProps, scheduleRequest, isScheduleRequestValid } =
    useStepThreeNewIeaDataHook(stepThreeHookProps);

  // Step 4 hook
  const stepFourHookProps = useMemo<StepFourDataHookProps>(() => {
    return {
      direction: coreInfoRequest.exchangeRole,
      codeSet: ownerConfigRequest.ownerSchedule?.userFieldSetId,
      connectorId: ownerConfigRequest.connectorId,
      hostSystemParameters: ownerConfigSetting?.hostSystemParameters,
      hostSystem: ownerConfigRequest.selectedConnector?.hostSystem,
      hostSystemName: ownerConfigRequest.selectedConnector?.hostSystemName,
    };
  }, [coreInfoRequest, ownerConfigRequest, ownerConfigSetting]);

  const {
    stepFourProps,
    hostParameterRequest,
    isHostParameterRequestValid,
    getUpdatedHostParametersForSaving,
  } = useStepFourNewIeaDataHook(stepFourHookProps);

  // Step 5 hook
  const stepFiveHookProps = useMemo<StepFiveDataHookProps>(() => {
    return {
      hostSystem: ownerConfigRequest.selectedConnector?.hostSystem!,
      hostSystemParameters: hostParameterRequest,
      direction: coreInfoRequest.exchangeRole,
      sapActivityFilterProps: {
        frequencyType: scheduleRequest.frequencyType,
      },
    };
  }, [
    coreInfoRequest,
    ownerConfigRequest,
    scheduleRequest,
    hostParameterRequest,
  ]);

  const {
    stepFiveProps,
    hostParametersWithActivityFilterRequest,
    isHostParametersWithActivityFilterRequestValid,
  } = useStepFiveNewIeaDataHook(stepFiveHookProps);

  // End of Step hook

  useEffect(() => {
    if (coreInfoRequest.exchangeRole === ConfigDirection.Sending) {
      setTotalStep(totalStepForSender);
    } else {
      setTotalStep(totalStepForReceiver);
    }
  }, [coreInfoRequest]);

  useEffect(() => {
    displayLoadingPanel();
    dispatch(loadOwnerConfigForExchangeAgreementAsync()).finally(
      hideLoadingPanel
    );
  }, [dispatch]);

  useEffect(() => {
    if (
      ownerConfigRequest.connectorId &&
      ownerConfigRequest.scheduleId &&
      ownerConfigRequest.selectedTemplateId
    ) {
      displayLoadingPanel();
      dispatch(
        loadConfigForExchangeAgreementAsync({
          templateId: ownerConfigRequest.selectedTemplateId,
          connectorId: ownerConfigRequest.connectorId,
          scheduleId: ownerConfigRequest.scheduleId,
          direction: ownerConfigRequest.selectedDirection,
        })
      ).finally(hideLoadingPanel);
    }

    return () => {
      dispatch(resetConfigForExchangeAgreement());
    };
  }, [
    dispatch,
    ownerConfigRequest.selectedDirection,
    ownerConfigRequest.selectedTemplateId,
    ownerConfigRequest.connectorId,
    ownerConfigRequest.scheduleId,
  ]);

  const areAllStepDataValid = useStepValidation({
    isCoreInfoRequestValid,
    isOwnerConfigRequestValid,
    isScheduleRequestValid,
    isHostParameterRequestValid,
    isHostParametersWithActivityFilterRequestValid,
    currentStep,
  });

  const handleSubmitIEA = () => {
    if (
      (coreInfoRequest.exchangeRole === ConfigDirection.Sending &&
        currentStep !== totalStepForSender) ||
      (coreInfoRequest.exchangeRole === ConfigDirection.Receiving &&
        currentStep !== totalStepForReceiver) ||
      !areAllStepDataValid
    ) {
      return;
    }

    const exchangeAgreementRequest: OwnerExchangeAgreementWriteRequest = {
      title: coreInfoRequest.title,
      comments: scheduleRequest.comments,
      startDate: scheduleRequest.startDate,
      expirationDate: scheduleRequest.expirationDate,
      executionCronExpression: scheduleRequest.executionCronExpression,
      predecessorAgreementId: scheduleRequest.predecessorAgreementId,
      partnerTenantId: coreInfoRequest.partnerTenantId,
      partnerRepresentativeId: coreInfoRequest.partnerRepresentativeId,
      templateId: ownerConfigRequest.selectedTemplateId,
      connectorId: ownerConfigRequest.connectorId,
      scheduleId: ownerConfigRequest.scheduleId,
      ownerRepresentativeIdentifiers: ownerConfigRequest.ownerRepresentatives,
      frequencyType: scheduleRequest.frequencyType,
      labelIdentifiers: scheduleRequest.labelIdentifiers,
      configSettings: JSON.stringify(
        getUpdatedHostParametersForSaving(
          hostParametersWithActivityFilterRequest ?? hostParameterRequest
        )
      ),
      ownerExchangeRole:
        coreInfoRequest.exchangeRole === ConfigDirection.Sending
          ? ExchangeRoleEnum.Sender
          : ExchangeRoleEnum.Receiver,
      lagInMinutes: scheduleRequest.lagInMinutes,
    };

    displayLoadingPanel();
    dispatch(createNewExchangeAgreementForOwnerAsync(exchangeAgreementRequest))
      .unwrap()
      .then((response: ExchangeAgreementDetailedResponse) => {
        toastSuccess("IEA created successfully.");
        navigate(`${NEW_IEAS}/${response.id}`);
      })
      .finally(hideLoadingPanel);
  };

  return (
    <>
      <PromineoModal isVisible={true} height={670} width={1400}>
        <div className="flex flex-col justify-between h-[620px] gap-y-4">
          <div className="flex flex-col justify-between h-[580px] gap-y-4">
            <div>
              <div className="flex justify-between">
                <div>
                  <span className="font-poppins text-base font-semibold">
                    New IEA
                  </span>
                </div>
                <StepProgressBar
                  totalSteps={totalStep}
                  currentStep={currentStep}
                />
              </div>
            </div>
            <div className="font-normal font-inter flex-grow border-b pb-4 border-lightGray">
              {/* using tab panel to prevent unmounting components on step changes */}
              <PromineoTabPanel
                selectedIndex={currentStep - 1}
                repaintChangesOnly={true}
                hideTabs={true}
              >
                <Item>
                  <StepOneNewIEA {...stepOneProps} />
                </Item>
                <Item>
                  <StepTwoNewIEA {...stepTwoProps} />
                </Item>
                <Item>
                  <StepThreeNewIEA {...stepThreeProps} />
                </Item>
                <Item>
                  <StepFourNewIEA {...stepFourProps} />
                </Item>
                <Item>
                  <StepFiveNewIEA {...stepFiveProps} />
                </Item>
              </PromineoTabPanel>
            </div>
          </div>
          <div>
            <div className="flex justify-between">
              <PromineoButton
                text={currentStep === 1 ? "Cancel" : "Back"}
                variant={PromineoButtonType.Secondary}
                onClick={handleCancelOrBackClick}
              />
              <PromineoButton
                text={currentStep === totalStep ? "Submit" : "Next Step"}
                onClick={handleNextOrSubmitClick}
                disabled={!areAllStepDataValid}
              />
            </div>
          </div>
        </div>
      </PromineoModal>
      {isCancelConfirmationDialogVisible && (
        <PromineoConfirmationDialog
          onConfirm={() => {
            props.onClose();
            setIsCancelConfirmationDialogVisible(false);
          }}
          onCancel={() => {
            setIsCancelConfirmationDialogVisible(false);
          }}
          content={`Are you sure you want to cancel creating the IEA?`}
          cancelButtonText="No"
          confirmButtonText="Yes"
        />
      )}
    </>
  );
}
